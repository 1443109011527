import { logoutUser } from "../Services/BorgAPIClient";
import Cookies from "universal-cookie";

const cookies = new Cookies();
    
export const handleLogout = async (navigate: any) => {
    const refreshToken = localStorage.getItem('refresh-token');
    console.log(`logging out`);
    //console.log(`logging out refresh token ${refreshToken}`);
    try{
        await logoutUser( refreshToken );
    }catch (e)
    {
        //Need a way to log errors. Story in backlog
    }
    localStorage.removeItem('refresh-token');
    localStorage.removeItem('userId');
    localStorage.removeItem('canPlay');
    cookies.remove('username');
    cookies.remove('userId');

    navigate("/");
};
