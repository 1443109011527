import React, { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { getUser } from 'src/Services/BorgAPIClient';
import { User } from 'src/BorgTypes';
import { Roles } from 'src/BorgEnums';

import borgLogo from "../../assets/img/Borg-logo.png";
import kepleraLogo from "../../assets/img/Keplera-logo.png";

const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";
const productLogo = isKepleraVersion ? kepleraLogo : borgLogo;

export const APP_BAR_HEIGHT_PX = 140; // rough estimate


type Props = {};

const AdminAppBar = (props: Props) => {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleOpenNavMenu = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(e.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickNavButton = (e: React.MouseEvent<HTMLElement>, key: string = "") => {
    setAnchorElNav(null);
    if (!key)
      navigate("/admin");
    else
      navigate(`/admin/${key.toLowerCase()}`);
  };

  const handleQuitAdmin = () => {
    window.close();
  };

  // Logged in User data (used for filtering pages)
  const getLoggedInUser = async () => {
    const userId = JSON.parse(localStorage.getItem('userId') as string);
    let user;
    if (userId) {
      try {
        user = await getUser(userId.replace(/"|'/g, ''));
        if (user) {
          setLoggedInUser(user.data);
        }
      } catch (err: any) {
        console.log(err);
      }
    };
  }
  
  //Conditionally render the Admin page based on role and build type 
  const isStandalone = process.env.REACT_APP_IS_STANDALONE === 'true';
  const isOrganizationAdmin = loggedInUser?.roles?.includes(Roles.OrganizationAdmin) ?? false;
  const pages = ['Users', 'Organizations', 'Sandboxes', 'Leaderboards', 'Permissions', 'System'];
  let filteredPages = pages;
  
  //Page filtering
  if (isStandalone) { //Remove all pages not included in standalone build
    filteredPages = filteredPages.filter(page => page !== 'Organizations' && page !== 'Permissions');
  }
  
  if (isOrganizationAdmin) {  //Organization Admin role can only see the Users page
    filteredPages = ['Users'];
  }

  useEffect(() => {
    getLoggedInUser();
  }, []);
  
  return (
    <AppBar position="sticky">
      <Container maxWidth={false}>
        <Toolbar disableGutters>

          <Box sx={{ flexGrow: 0.5, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {filteredPages.map((page) => (
                <MenuItem key={page} onClick={(e) => handleClickNavButton(e, page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <div className="borg-logo-appbar">
            <img alt="logo" src={productLogo} />
          </div>

          <Link component={RouterLink} to="/admin" sx={{ textDecoration: 'none' }}>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700
              }}
            >
              Admin Panel
            </Typography>
          </Link>

          <Link component={RouterLink} to="/admin" sx={{ textDecoration: 'none' }}>
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 700,
                textDecoration: 'none',
              }}
            >
              Admin Panel
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1, gap: 1, display: { xs: 'none', md: 'flex' } }}>
            { /* TODO - Use Tabs */}
            {filteredPages.map((page) => (
              <Link key={page} component={RouterLink} to={`/admin/${page}`} sx={{ textDecoration: 'none' }}>
                <Button
                  key={page}
                  sx={{ my: 2, display: 'block' }}
                >
                  {page}
                </Button>
              </Link>
            ))}
          </Box>

          <Tooltip title="Close Admin Panel">
            <IconButton
              sx={{ marginLeft: 'auto' }}
              size="large"
              aria-label="close admin panel"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleQuitAdmin}
              color="inherit"
            >
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AdminAppBar;