import React from 'react';
import MainPageTemplateCustomLayout from './MainPageTemplateCustomLayout';

type Props = { 
  title: string,
  error?: string,
  message?: string,
  children: React.ReactNode, 
};

function MainPageTemplateColumnLayout(props: Props) {

  return (
    <MainPageTemplateCustomLayout fixedFooter {...props}>
    <div className="main" style={{ marginLeft: '0px', marginRight: '0px' }}>
        <div className="box-container">
          <div className="header">{props.title}</div>
          <div className="box-column">
            {props.children}
          </div>
        </div>
      </div>
    </MainPageTemplateCustomLayout>
  );  

}

export default MainPageTemplateColumnLayout;




