import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { resetUserPassword } from "../Services/BorgAPIClient";
import MainPageTemplateColumnLayout from './Templates/MainPageTemplateColumnLayout';
import BorgStyleTextField from 'src/Components/BorgStyleTextField';
import BorgStyleButton from 'src/Components/BorgStyleButton';

type Props = {};

const PasswordResetPage = (props: Props) => {

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({} as any);

  const navigate = useNavigate();
  
  const submitPasswordReset = async () => {
    try {
      
      await resetUserPassword({
        username,
        password,
        passwordConfirmation,
      });
      navigate("/", { state: { fromResetPassword: true, success: true } } );
    } catch(err: any) {
      if(err && err.errors) {
        setError(`Password Reset failed: ${err.title}`);
        setFieldErrors(err.errors);
      } else if(err) {
        setError(err.title);
        setFieldErrors(null);
      } else {
        setError("Password Reset Failed");
        setFieldErrors(null);
      }
    }
  };

  const resetErrors = (field : string) => {
    setError("");
    if(fieldErrors && field) {
      fieldErrors[field] = "";
    }
  };

  return (
    <MainPageTemplateColumnLayout title="Reset Password">
      <div>
        <div className="box-column">
          {/* Username input field */}
            <BorgStyleTextField
              id="textbox-username"
              label="Username"
              value={username}
              autoFocus
              errorText={fieldErrors?.Username && fieldErrors?.Username[0]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                resetErrors("Username");
                setUsername(e.target.value)
              }}
            />
          {/* Password and confirmation input fields */}
            <BorgStyleTextField
              id="textbox-new-password"
              label="New Password"
              value={password}
              type="password"
              errorText={fieldErrors?.Password && fieldErrors?.Password[0]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                resetErrors("Password");
                setPassword(e.target.value)
              }}
            />
            <BorgStyleTextField
              id="textbox-confirm-password"
              label="Confirm Password"
              value={passwordConfirmation}
              type="password"
              errorText={fieldErrors?.PasswordConfirmation && fieldErrors?.PasswordConfirmation[0]}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                resetErrors("PasswordConfirmation");
                setPasswordConfirmation(e.target.value)
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>  {
                if(e.key === "Enter") { 
                  submitPasswordReset(); 
                }
              }}
            />
        { !fieldErrors && <div className="error">{error}</div> }
        </div>
        <div className="footer">
          <BorgStyleButton id="button-submit" onClick={submitPasswordReset}>Reset Password</BorgStyleButton>
          <p />
          <Link className="link-button" to="/">Back to Login</Link> 
        </div>
      </div>
    </MainPageTemplateColumnLayout>
  );
};

export default PasswordResetPage;
