import React, { useState } from "react";
import { loginUser, getUserPasswordResetPending } from "../Services/BorgAPIClient";
import BorgStyleTextField from "./BorgStyleTextField";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import BorgStyleButton from "./BorgStyleButton"

interface LoginProps {
  onNeedResetPassword: () => void;
}

const isStandAlone = process.env.REACT_APP_IS_STANDALONE === "true";
const productName = process.env.REACT_APP_PRODUCT_NAME ? process.env.REACT_APP_PRODUCT_NAME : "borg"
console.log(`IS STANDALONE=${isStandAlone} PRODUCT NAME=${productName}`)

const Login: React.FC<LoginProps> = ({ onNeedResetPassword }) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [fieldErrors, setFieldErrors] = React.useState({} as any);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [isBuyOpen, setIsBuyOpen] = useState(false);

  const passwordResetMsg = "If you forgot your password, please contact your administrator to have it reset.";

  const submitLogin = async () => {

    let mustReset;
    try {
      mustReset = await getUserPasswordResetPending(username);
      if (mustReset) {
        onNeedResetPassword(); // Inform the parent component that reset is needed
        return;
      }
      setPassword("");
    } catch (err: any) {
      // will return NOT FOUND if no password reset is pending
    }

    if (!mustReset) {
      try {
        let userId = await loginUser({
          username: username,
          password: password,
        });

        cookies.set("username", username);
        cookies.set("userId", userId);

        navigate("/dashboard");

      } catch (err: any) {
        if (err && err.errors) {
          let errMsg = `Login failed: ${err.title}`;
          if (err.title.includes("password")) errMsg = errMsg + `. ${passwordResetMsg}`
          setError(errMsg);
          setFieldErrors(err.errors);
        } else if (err) {
          let errMsg = err.title;
          if (err.title.includes("password")) errMsg = errMsg + `. ${passwordResetMsg}`
          setError(errMsg);
          setFieldErrors(null);
        } else {
          setError("Login Failed");
          setFieldErrors(null);
        }
      }

      setPassword("");
    }
  };
  const toggleBuyModal = async () => {
    setIsBuyOpen(!isBuyOpen);
  }
  const resetErrors = (field: string) => {
    setError("");
    if (fieldErrors && field) {
      fieldErrors[field] = "";
    }
  };
  var pageType = "";  //Used to identify how RegisterPage will be rendered

  //Custom Components based off build type (Standalone or not)
  let registrationLinks;
  if (!isStandAlone) {
    registrationLinks = (
      <div>
        <p />
        {/* <Link className="link-button" to={{
            pathname: '/register',
          }} state={pageType = "PurchaseSubscription"} >Purchase Subscription</Link>
          <p /> */}
        <Link id="link-button-register-join-organization" className="link-button" to={{
          pathname: '/register',
        }} state={pageType = "JoinOrganization"} >Register with access code</Link>
        <p />
        <div className="copyright">To request an access code, email <a href="mailto:support@auria.space" style={{ color: 'rgba(255, 182, 61)' }}>support@auria.space</a></div>
      </div>
    )
  }
  else {
    registrationLinks = (
      <div>
        <p />
        <Link id="link-button-register-standalone" className="link-button" to={{
          pathname: '/register',
        }} state={pageType = "Standalone"} >Create Account</Link>
      </div>
    )
  }

  return (
    <div className="box-column">
      <div className="box-column">
        <BorgStyleTextField
          id="textbox-username"
          label="Username"
          value={username}
          autoFocus
          errorText={fieldErrors?.Username && fieldErrors?.Username[0]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            resetErrors("Username");
            setUsername(e.target.value)
          }}
        />
        <BorgStyleTextField
          id="textbox-password"
          label="Password"
          value={password}
          type="password"
          errorText={fieldErrors?.Password && fieldErrors?.Password[0]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            resetErrors("Password");
            setPassword(e.target.value)
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              submitLogin();
            }
          }}
        />
        {!fieldErrors && <div className="error">{error}</div>}
      </div>
      <div className="footer">
        <BorgStyleButton id="button-submit" onClick={() => submitLogin()}>Login</BorgStyleButton>
        {registrationLinks}
      </div>
    </div>
  );
};

export default Login;
