import moment from 'moment';
import { useState } from 'react';
import ChangeConfirmationDialog from './ChangeConfirmationDialog';

interface groupDataType {
  id: string,
  name: string,
  password: string,
  ownerId: string,
  caption: "",
  memberIds: [],
  activeSessionId: string,
  Competitions: {
    id: string,
    name: string,
    startDate: Date;
    endDate: Date | null;
    type: number | null,
  }
}

const GroupManagement = ({ groups, onDeleteGroup, onStartSession, onStopSession } : {
    groups: groupDataType[]; 
    onDeleteGroup: (groupId: string) => void;
    onStartSession: (groupData: groupDataType) => void; 
    onStopSession: (groupData: groupDataType) => void;
  }) => {

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [groupToChange, setGroupToChange] = useState<groupDataType | null>(null);

  const calculateTimeAgo = (time: Date) => {
    return moment(time).fromNow();
  };

  const closeConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleDeleteClick = (group: groupDataType) => {
    setGroupToChange(group);
    setOpenConfirmDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if(groupToChange) onDeleteGroup(groupToChange.id);    
    setOpenConfirmDeleteDialog(false);
    setGroupToChange(null);
  };

  return (
    <div className="box-column">
      {groups.map((group) => (
        <div key={group.name} className='box-column-dense'>
          <div style={{alignItems: 'left', whiteSpace: 'nowrap'}}>
            <div>Name: {group.name}</div>
            <div>Session Code: {group.password}</div>
          </div>
          {!group.activeSessionId ? (
            <button className="secondary-btn" onClick={() => onStartSession(group)}>
              Start Session
            </button>
          ) : (
            // When a session has started switch layout
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div className="text-blue" style={{ marginRight: '7px', fontSize: '12px'}}>
                <div> Session started</div>
                <div>{calculateTimeAgo(group.Competitions?.startDate ?? 'ERROR: NO START DATE')}</div>
              </div>
              <button className="secondary-btn-fitted" onClick={() => onStopSession(group)}>
                Stop
              </button>
            </div>
          )}
          <button className="secondary-btn-red" onClick={() => handleDeleteClick(group)}>Delete Group</button>
        </div>
      ))}
      {/* Deletion Confirmation Dialog */}
      <ChangeConfirmationDialog
        title="Confirm Delete"
        message={`This action cannot be undone.  Are you sure you want to delete "${groupToChange?.name}"?`}
        open={openConfirmDeleteDialog}
        onClose={closeConfirmDeleteDialog}
        onCancel={closeConfirmDeleteDialog}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default GroupManagement;