import React, { useEffect, useState, useContext } from 'react';
import { Badge, Box, Tooltip, Typography } from '@mui/material';
import { getUserStats } from 'src/Services/BorgAPIClient';
import { LoggedInUserContext } from '../Admin/AdminPageContainer'; // Import context
import { Roles } from 'src/BorgEnums';

type UserStatType = {
  activeUsers: number;
  userLimit: number;
  numberOfAdmins: number;
};
type statChangeType = {
  statsChanged: boolean;
};

const UserLimitPanel = ({ statsChanged }: statChangeType) => {
  const [userStats, setUserStats] = useState<UserStatType | null>(null);
  const loggedInUser = useContext(LoggedInUserContext);

  useEffect(() => {
    init();
  }, [statsChanged, loggedInUser]);

  const init = async () => {
    if (loggedInUser)  //if logged in user has been obtained
    {
      try {
        let userStats: { data: UserStatType; }; //initialize as blank UserStatType

        if (loggedInUser?.roles[0] === Roles.OrganizationAdmin)
          userStats = await getUserStats(loggedInUser.organization);
        else
          userStats = await getUserStats();

        setUserStats(userStats.data as UserStatType);
      } catch (error) {
        console.error('Failed to fetch user stats:', error);
      }
    }
  };

  // Ensure activeUsers and userLimit is defined before using it
  const activeUsers = userStats?.activeUsers ?? 0;
  const userLimit = userStats?.userLimit ?? 0;

  const hoverTip = 'If the user count is reached, it is necessary to delete or disable users in order to register new ones.';

  return (
    <Tooltip title={hoverTip}>
      <Badge badgeContent={"?"} color="primary" >
        <Box className="user-limit-panel" sx={{
          height: '50px',
          minWidth: "170px",
          maxWidth: "auto",
          borderRadius: 2,
          bgcolor: 'primary.light'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* Active Users */}
            <Typography variant="h1" sx={{
              color: (activeUsers >= (userLimit * 0.95) && (userLimit - activeUsers) <= 25) || activeUsers === (userLimit - 1) ? 'red' : '#F5B41E',
              fontSize: '2rem', ml: .75, mr: .5
            }}>
              {activeUsers}
            </Typography>
            <Box>
              <Typography variant="body1" sx={{ fontSize: '.75rem', color: 'white' }}>
                Active
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '.75rem', color: 'white' }}>
                {activeUsers > 1 ? 'Users' : 'User'}
              </Typography>
            </Box>

            {/* Division Line */}
            <Box sx={{
              width: '2px', height: '30px',
              backgroundColor: 'white', transform: 'skewX(-15deg)',
              ml: 1, mr: 1, mt: 1, mb: 1
            }} />

            {/* User Limit */}
            <Typography variant="h1" sx={{ color: '#F5B41E', fontSize: '2rem', mr: .5 }}>
              {userLimit}
            </Typography>
            <Box>
              <Typography variant="body1" sx={{ fontSize: '.75rem', color: 'white' }}>
                User
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '.75rem', color: 'white', mr: 1 }}>
                Limit
              </Typography>
            </Box>
          </Box>
        </Box>
      </Badge>
    </Tooltip>
  );
};

export default UserLimitPanel;