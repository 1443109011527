import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';

const BannerMessage = ({ visible, message, onCloseBanner} : {
        visible: boolean;
        message: string | undefined;
        onCloseBanner: () => void;
    }) => { 
        
    const [showDialog, setShowDialog] = useState(false);

    const handleToggleDialog = () => {
        setShowDialog(!showDialog);
    };

    const handleCloseBanner = () => {
        onCloseBanner();
    };

    return (
        <>
            {visible && (
                <div className="banner" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '0 auto', maxWidth: 'calc(100% - 80px)' }}>
                        <p style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            flexGrow: 1,
                            marginRight: '8px',
                            minWidth: 0 // This allows the message text to shrink below its content size
                        }}>
                            {message}
                        </p>
                        <IconButton
                            aria-label="more info"
                            onClick={handleToggleDialog}
                            style={{ flexShrink: 0 }} // Prevents the button from shrinking
                        >
                            <LaunchIcon />
                        </IconButton>
                    </div>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseBanner}
                        style={{ flexShrink: 0 }} // Prevents the button from shrinking
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            )}
            {showDialog && (
                <Dialog onClose={handleToggleDialog} open={showDialog} >
                    <DialogTitle className='banner-modal'  sx={{ color: 'white' }}>Systemwide Message</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleToggleDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <DialogContent className='banner-modal'>
                        <Typography variant="body2" style={{ color: 'white' }}>
                            {message}
                        </Typography>
                        <Typography variant="body2" style={{ color: 'white', marginTop: '1rem' }}>
                            For more info reach out to us at: <a href="mailto:support@auria.space" style={{ color: 'white' }}>support@auria.space</a>
                        </Typography>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default BannerMessage;