import { useLocation, Link } from "react-router-dom";

interface responseData {
  message?: string,
  warning?: string,
  pageType: string
}

const SuccessfulPayment = () => {
  const location = useLocation();
  const response = location.state as responseData ;

  return (
    <div style={{ textAlign: "center" }}>
      {response.pageType === 'PurchaseSubscription' &&( <h1>Payment Successful</h1>)}
      {response.pageType === 'JoinOrganization' &&( <h1>Registration Successful</h1>)}
      <p>{response.message}</p>
      {response.warning && <p>{response.warning}</p> }
      <p>Please <Link className="link-button" to="/">login</Link> to access your account.</p>
    </div>
  );
};

export default SuccessfulPayment;


