
import React, { useCallback, useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Box, Typography, TextField, IconButton, FormControl, FormHelperText } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SendIcon from '@mui/icons-material/Send';
import { getBannerMessage, setBannerMessage } from 'src/Services/BorgAPIClient';

type BannerMessageType = {
  startDate: Date;
  endDate: Date;
  message: string;
};

const SystemBannerMessage = () => {
  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = React.useState<Dayjs | null>(null);
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState(false); // Flag for validation error

  useEffect(() => {
    fetchBannerMessage();
  }, []);

  const fetchBannerMessage = useCallback(async () => {
    try {
      const result = await getBannerMessage();
      const bannerMessageData = result.data as BannerMessageType;

      //If there is no banner message then set none of the values
      if (bannerMessageData) {
        setMessage(bannerMessageData.message);

        // Ensure banner StartDate and banner EndDate are Date objects
        const startDate = bannerMessageData.startDate instanceof Date ? bannerMessageData.startDate : new Date(bannerMessageData.startDate);
        const endDate = bannerMessageData.endDate instanceof Date ? bannerMessageData.endDate : new Date(bannerMessageData.endDate);

        setStartDate(dayjs(startDate));
        setEndDate(dayjs(endDate));
      }

    } catch (error) {
      console.error('Failed to fetch Banner Message:', error);
    }
  }, []);

  const handleSendMessage = () => {
    if (validateDateSelection()) { // Check for valid date range before sending
      //Create banner message data to send to the backend fom the values set 
      const bannerMessageData: BannerMessageType = {
        startDate: startDate ? startDate.toDate() : new Date(),
        endDate: endDate ? endDate.toDate() : new Date(),
        message: message || ''
      };
      setBannerMessage(bannerMessageData);
    }
  }

  const validateDateSelection = (): boolean => {
    if (startDate && endDate && startDate.isAfter(endDate)) {
      setError(true); // Set error flag if start date is after end date
      return false;
    } else {
      setError(false); // Clear error flag if selection is valid
      return true;
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className='system-section' >
        <Typography variant="h6" gutterBottom>
          BANNER MESSAGE
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <DateTimePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            sx={{ maxWidth: '225px', width: '100%' }}
          />
          <DateTimePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            sx={{ maxWidth: '225px', width: '100%' }}
            disablePast
          />
          <TextField
            label="Enter Message"
            variant="standard"
            value={message}
            fullWidth
            InputLabelProps={{ shrink: !!message }} //Removes the label text from the text field if message is not null
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
          />
          {/* div and style fixes issue with hover graphic */}
          <div style={{ alignContent: 'center' }}>
            <IconButton
              aria-label="send"
              onClick={handleSendMessage}
            >
              <SendIcon />
            </IconButton>
          </div>
          <FormControl error={error}>
            <FormHelperText className="error">
              {error ? 'Start date must be before end date' : ''}
            </FormHelperText>
          </FormControl>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};
export default SystemBannerMessage;