import React from "react";
import { useLocation, Link } from "react-router-dom";

interface ErrorDetails {
  title?: string;
  errors?: Record<string, string[]>;
  pageType: string;
}

const FailedPayment: React.FC = () => {
  const location = useLocation();
  const message = location.state as  ErrorDetails;

  return (
    <div style={{ textAlign: "center" }}>
      {message.pageType === 'PurchaseSubscription' && 
        <div>
          <h1>Payment Failed</h1>
          <p>Unfortunately, there was an issue processing your transaction.  Please double-check your information and try again, or contact support for assistance.</p>
        </div>      
      }
      {message.pageType === 'JoinOrganization' && 
        <div>
          <h1>Registration Failed</h1>
          <p>Unfortunately, there was an issue verifying your credentials.  Please double-check your information and try again, or contact support for assistance.</p>
        </div>      
      }
      {message && <p>Error: {message.title}</p>}
      {message?.errors && (
        <ul>
        {Object.entries(message.errors).map(([key, value]) => (
          <li key={key}>
            {key}: {value.join(", ")}
          </li>
          ))}
        </ul>
      )}
      <Link className="link-button" to={{
          pathname: '/register',
        }} state= {message.pageType} >Back to Register Page</Link>
    </div>
  );
};
export default FailedPayment;
