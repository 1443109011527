import React, { createContext, useEffect, useState } from 'react';

import { CustomSnackbar, CustomAlert } from "../../Components/CustomSnackbar";
import AdminAppBar from "../../Components/Admin/AdminAppBar";
import auriaLogo from "../../assets/img/AURIA_LOGO_WHITE+COLOR_64.png";
import Box from '@mui/material/Box';
import { getUser } from 'src/Services/BorgAPIClient';

type User = {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  rankTitle: string;
  roles: number[];
  enabled: boolean;
  resetPasswordPending: boolean;
  lastActive: Date;
  organization: string;
};

// Create context to share current logged in user data to all components with in the admin container
const LoggedInUserContext = createContext<User | null>(null);

type Props = { children: React.ReactNode };

const AdminPageContainer = (props: Props) => {
  const [notification, setNotification] = React.useState("");
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
  
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const userId = JSON.parse(localStorage.getItem('userId') as string);
      if (userId) {
        const user = await getUser(userId.replace(/"|'/g, ''));
        if (user) {
          setLoggedInUser(user.data);
        }
      }
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  return (
    <LoggedInUserContext.Provider value={loggedInUser}>
      <div>
        <AdminAppBar />
        <CustomSnackbar
          open={!!notification}
          autoHideDuration={6000}
          onClose={() => setNotification("")}
        >
          <CustomAlert onClose={() => setNotification("")}>
            {notification}
          </CustomAlert>
        </CustomSnackbar>
        {props.children}
        <Box className="login-footer">
          <a href="https://www.auria.space/" target="_blank">
            <img alt="Auria, LLC Logo" src={auriaLogo} />
          </a>
          <div className="copyright">© 2022 Boecore, LLC, a wholly owned subsidiary of Auria Space, LLC</div>
        </Box>
      </div>
    </LoggedInUserContext.Provider>
  );
};

export default AdminPageContainer;
export { LoggedInUserContext };