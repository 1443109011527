import { useState } from 'react';
import ChangeConfirmationDialog from '../../Components/ChangeConfirmationDialog';
import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { removeAllScoreCards } from '../../Services/BorgAPIClient';

type Props = {};

const LeaderboardsAdminPage = (props: Props) => { 

  const [openConfirmClearAllDialog, setOpenConfirmClearAllDialog] = useState(false);

  const closeConfirmClearAllDialog = () => {
    setOpenConfirmClearAllDialog(false);
  };

  const handleClearAllClick = () => {
    setOpenConfirmClearAllDialog(true);
  };

  const handleConfirmClearAll = async () => {
    await removeAllScoreCards();
    setOpenConfirmClearAllDialog(false);
  };
  
  return (
    <div>
      <Box textAlign='center' sx={{ margin: 10 }}>
        <Typography>Click to clear all leaderboards.</Typography>
        <Button
          variant='contained'
          color='error'
          startIcon={<DeleteIcon />}
          onClick={() => handleClearAllClick()}
        >
          Clear All Leaderboards
        </Button>
      </Box>
      <ChangeConfirmationDialog 
        title="Confirm Clear All Leaderboards" 
        message={"This affects all users. This action cannot be undone. Are you sure you want to clear all leaderboards?"}
        open={openConfirmClearAllDialog} 
        onClose={closeConfirmClearAllDialog} 
        onCancel={closeConfirmClearAllDialog} 
        onConfirm={handleConfirmClearAll}
      />
    </div>
  );
}
export default LeaderboardsAdminPage;