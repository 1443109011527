import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AboutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const isStandAlone = process.env.REACT_APP_IS_STANDALONE === "true";
const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";

const productFullName = isKepleraVersion ? "Keplera" : "Battlespace Operational Readiness Game Space (BORG Space)"
const productShortName = isKepleraVersion ? "Keplera" : "BORG"
const productGameVersion = isKepleraVersion ? "1.0" : "2.1.2"
const productWebVersion = isKepleraVersion ? "1.0" : "2.1.2"

const AboutModal: React.FunctionComponent<AboutModalProps> = ({ isOpen, onClose }) => {


    return (
        <Dialog 
            open={isOpen} 
            onClose={onClose}
        >
            <DialogTitle>About</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Typography variant="h5" style={{ color: 'white' }}>{productFullName}</Typography>
                <Typography variant="body2" style={{ color: 'white' }}>
                    © 2022 Boecore, LLC, a wholly owned subsidiary of Auria Space, LLC.
                </Typography>
                <Typography variant="body2" style={{ color: 'white' }}>
                    {productShortName} Game v{productGameVersion} 11/8/2024, {productShortName} Web App v{productWebVersion}{isStandAlone ? 's' : ''} 11/8/2024
                </Typography>
                <Typography variant="body2" style={{ color: 'rgba(255, 182, 61)', marginTop: '1rem', fontSize: '1.1rem' }}>
                    For help, contact us at <a href="mailto:support@auria.space" style={{ color: 'rgba(255, 182, 61)' }}>support@auria.space</a>
                </Typography>
                <Typography variant="body2" style={{ color: 'white', marginTop: '1rem' }}>
                    This web application is intended for authorized users only and is governed by the terms of use and privacy policy provided. 
                    Unauthorized access or use may result in termination of your access and legal action. By using this web application, you agree 
                    to comply with all applicable laws and regulations regarding its use and the protection of any data provided herein. If you 
                    are not an authorized user or have received access to this application in error, please cease all use and 
                    contact <a href="mailto:support@auria.space" style={{ color: 'rgba(255, 182, 61)' }}>support@auria.space</a>
                </Typography>
            </DialogContent>
        </Dialog>
    );
};

export default AboutModal;