import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { IconButton, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface CreateGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (groupName: string) => void;
}

const CreateGroupModal: React.FunctionComponent<CreateGroupModalProps> = ({ isOpen, onClose, onConfirm }) => {

  const [groupName, setGroupName] = useState("");

  const handleClose = () => {
    onClose();
    setGroupName("");
  }

  const handleConfirm = () => {
    onConfirm(groupName)
    setGroupName("");
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
    >
      <DialogTitle>Create New Group</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0, width: "340px" }}>
        <TextField
          helperText="Name must be 32 characters or less."
          autoFocus
          autoComplete="off"
          margin="dense"
          id="name"
          label="Group Name"
          type="text"
          fullWidth
          variant="standard"
          value={groupName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CANCEL</Button>
        <Button type="submit" onClick={handleConfirm}>CONFIRM</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroupModal;