import React from "react";

type Props = {
  id: string;
  onClick?: any;
  onSubmit?: any;
  type?: "button" | "reset" | "submit" | undefined;
  disabled?: boolean;
  style?: any;
  children: React.ReactNode;
};

const BorgStyleButton = ({id, onClick, onSubmit, type, disabled, style, children} : Props) => {

  return <button
    id={id}
    type={type !== undefined ? type : "button"}
    className="secondary-btn"
    disabled={disabled}
    style={style}
    onSubmit={onSubmit}
    onClick={onClick}
  >
    {children}
  </button>
}

export default BorgStyleButton;