import React from "react";
import TextField from "@mui/material/TextField";

const colorBorgOrange = "rgb(255, 182, 61)";
const colorBorgBlue = "rgb(87,236,243)";
const colorError = "red";

type AppProps = {
  id: string;
  label: string;
  autoFocus?: boolean;
  autoComplete?: string;
  type?: string;
  value?: string;
  required?: boolean;
  errorText?: string;
  onChange?: any;
  onKeyDown?: any;
};


const BorgStyleTextField = ({id, label, autoFocus, autoComplete, type, value, required, errorText, onChange, onKeyDown}: AppProps) => {
  
  const [hover, setHover] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  return (
    <TextField
      style={{ 
        width: "220px",
        borderLeft: "3px solid", 
        borderLeftColor: (hover || focused) ? colorBorgBlue : (errorText ? colorError : colorBorgOrange)
      }}
      id={id}
      label={label}
      autoFocus={autoFocus}
      // hide border
      variant="standard"
      autoComplete={autoComplete}
      InputProps={{
        disableUnderline: true,
        style: { 
          color: colorBorgOrange,
          marginLeft: "14px"
        }
      }}
      InputLabelProps={{
        style: {
          color: "rgb(118, 69, 26)",
          marginLeft: "14px"
        }
      }}
      FormHelperTextProps={{
        style: {
          marginLeft: "14px",
          marginTop: "0px"
        }
      }}
      type={type}
      value={value}
      required={required}
      error={errorText !== undefined && errorText !== ""}
      helperText={errorText}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    />
  );
};

export default BorgStyleTextField;