import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { MouseEventHandler } from "react";


type ChangeConfirmationDialogProps = {
  title: string;
  message: string;
  open: boolean;
  onClose: MouseEventHandler;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
};

const ChangeConfirmationDialog = (props: ChangeConfirmationDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button onClick={props.onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeConfirmationDialog;