import { checkPrime } from "crypto";
import React, {useEffect} from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Roles } from "../BorgEnums";
import { checkRole } from "../Services/BorgAPIClient";

export interface IRBACProps {
    allowedRole: Roles[];
}

const Auth = (props: IRBACProps) => {

    const [hasAccess, setHasAccess] = React.useState<boolean>(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);
    const [isPending, setPending] = React.useState<boolean>(true);

    useEffect(() => {

        const checkForAccess = async () => {
            let refreshToken = localStorage.getItem("refresh-token");
            if(refreshToken) {
                setIsLoggedIn(true);
                let tmpHasAccess = await checkRole(props.allowedRole);
                setHasAccess(tmpHasAccess);
                setPending(false);
            } else {
                setPending(false);
            }
        }

        checkForAccess();

    }, []);

    if (isPending) {
        return <div>Loading...</div>;
    }

    if(!isLoggedIn) {
        localStorage.setItem("loginError", "You must log in first");
        return <Navigate to="/" />;
    }

    if (!hasAccess) {
        return <Navigate to="/unauthorized" state={{errorCode: 403, errorText: "You don't have access to this!", errorHelp: "Contact your administrator for access"}}/>;
    }

    return <Outlet />;
};

export default Auth;