import { Roles } from '../BorgEnums';
import { fetchFromHttp, postToHttp, patchToHttp, deleteFromHttp, putToHttp } from './AxiosService';

export const getAllUsers = async (orgName?: string) => {
  let result;

  //If an orgName is passed send query parameter, otherwise do not pass the parameter
  if(orgName)
    result = await fetchFromHttp(`/users?orgName=${orgName}`);
  else
    result = await fetchFromHttp(`/users`);

  return result;
};

export const getUserStats = async (orgName?: string) => {
  let userStats;

    //If an orgName is passed send query parameter, otherwise do not pass the parameter
    if(orgName)
      userStats = await fetchFromHttp(`/users/stats?orgName=${orgName}`);
    else
      userStats = await fetchFromHttp('/users/stats');

  return userStats;
};

export const getAccessCodes = async () => {
  let result = await fetchFromHttp('/users/accessCodes');
  return result;
}

export const generateAccessCode = async () => {
  let result = await postToHttp('/users/accessCodes');
  return result;
}

export const deleteAccessCode = async (accessCodeId: string) => {
  let res = await deleteFromHttp(`/users/accessCodes/${accessCodeId}`)
  return res;
}

export const createOrganization = async (orgName:string) => {
  let result = await postToHttp(`/users/organizations/${orgName}`);
  return result;
}

export const getOrganization = async (orgName: string) => {
  let res = await fetchFromHttp(`/users/organizations/${orgName}`);
  return res;
};

export const getAllOrganizations = async () => {
  let res = await fetchFromHttp(`/users/organizations`);
  return res;
};

export const deleteOrganization = async (orgName: string) => {
  let res = await deleteFromHttp(`/users/organizations/${orgName}`)
  return res;
}

export const assignOrganizationToAccessCode = async (accessCode:string, organizationId:string) => {
  await patchToHttp(`/users/accessCodes/${accessCode}/${organizationId}`);
}

export const loginUser = async (user: any) => {
  let res = await postToHttp('/auth/session', user);
  localStorage.setItem('refresh-token', res.refreshToken);
  let userIdStr = JSON.stringify(res.id);
  localStorage.setItem('userId', userIdStr);
  localStorage.setItem('canPlay', JSON.stringify(res.canPlay));
  return userIdStr;
}

export const logoutUser = async (refreshToken: any) => {
  await deleteFromHttp('/auth/session', refreshToken);
}

//Check if user has role permissions to access resource
export const checkRole = async (roles: Roles[]) => {

  let refreshToken = localStorage.getItem('refresh-token');  
  if(!refreshToken) return false

  let res = await fetchFromHttp(`/auth/session/roles`);
  let userRoles = res.data;
  let hasRole = false;

  roles.forEach(role => {
    if (userRoles.includes(role)) {
      hasRole = true;
    }
  });

  return hasRole;
}

export const getUser = async (userId: string) => {
  let res = await fetchFromHttp(`/users/${userId}`);
  return res;
};

export const updateUser = async (user: any) => {
  await putToHttp(`/users/${user.id}`, user);
};

export const toggleUserEnabled = async (userId: string) => {
  await patchToHttp(`/users/${userId}/enabled`);
};

export const deleteUser = async (userId: string) => {
  let res = await deleteFromHttp(`/users/${userId}`)
  return res;
}

export const updateUserRole = async (userId: string, newRole: number) => {
  let res = await patchToHttp(`/users/${userId}/role/${newRole}`);
  return res;
};

export const forceUserPasswordReset = async (userId: string) => {
  let res = await postToHttp(`/users/passwordResets/${userId}`)
  return res;
}

export const getUserPasswordResetPending = async (userName: string) => {
  let res = await fetchFromHttp(`/users/passwordResets/${userName}`)
  return res.data;
}

export const resetUserPassword = async (request: any) => {
  let res = await patchToHttp(`/users/passwordResets/${request.username}`, request)
  return res;
}

export const getAllSandboxes = async () => {
  let result = await fetchFromHttp('/sandboxes');
  return result;
};

export const deleteSandbox = async (sandboxId: string) => {
  let res = await deleteFromHttp(`/sandboxes/${sandboxId}`)
  return res;
}

export const removeAllScoreCards = async () => {
  let res = await deleteFromHttp('/scores');
  return res;
}

export const stripePayandRegisterUser = async (userData: any , paymentMethodId: any) => {
  const res = await postToHttp('/payments/register-and-pay', {
    userRegistrationDetails: userData,
    paymentMethodId: paymentMethodId,
  });
  return res;
};

export const validateUserRegistration = async (userData: any) => {
  const res = await postToHttp('/users/validate-registration', userData);
  return res;
};

export const registerUserWithOrganization = async (userData: any) => {
  const res = await postToHttp(`/users/${userData.organization}`, userData);
  return res;
};

export const registerPaymentFreeUser = async (userData: any) => {
  const res = await postToHttp(`/users/paymentFreeRegistration`, userData);
  return res;
};

export const updateOrganizationPaidStatus = async (orgId: string, isPaid: boolean) => {
  let res = await patchToHttp(`/users/organizations/${orgId}/status?isPaid=${isPaid}` );
  return res;
};

export const updateOrganizationUserLimit = async (orgId: string, userLimit: number) => {
  let res = await patchToHttp(`/users/organizations/${orgId}/userlimit?userLimit=${userLimit}` );
  return res;
};

export const addGroup = async (groupData: any) => {
  let res = await postToHttp('/groups', groupData);
  return res;
};

export const getGroups = async (ownerUserId?: string) => {
  let res = await fetchFromHttp(`/groups?ownerUserId=${ownerUserId}`);
  return res;
};

export const removeGroup = async (groupId: string) => {
  let res = await deleteFromHttp(`/groups/${groupId}`);
  return res;
}

export const startSession = async (groupData: any) => {
  let res = await postToHttp(`/groups/${groupData.id}/activesession`, groupData);
  return res;
};

export const getSession = async (groupId: string) => {
  let res = await fetchFromHttp(`/groups/${groupId}/activesession`);
  return res;
};

export const stopSession = async (groupId: string) => {
  let res = await deleteFromHttp(`/groups/${groupId}/activesession`);
  return res;
};

export const setBannerMessage = async (bannerMessage: any) => {
  await postToHttp(`/systemSettings/bannerMessage`, bannerMessage);
};

export const getBannerMessage = async () => {
  let res = await fetchFromHttp(`/systemSettings/bannerMessage`);
  return res;
};

export const cleanupDatabaseAPI = async () => {
  let res = await deleteFromHttp(`/test/cleanupDatabase`)
  return res;
}