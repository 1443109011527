import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Login from "../Components/Login";
import { handleLogout } from '../Components/LogoutUtils';
import MainPageTemplateColumnLayout from './Templates/MainPageTemplateColumnLayout';
import borgLogo from "../assets/img/Borg-logo.png";
import kepleraLogo from "../assets/img/Keplera-logo.png";

const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";
const productWelcome = isKepleraVersion ? "Welcome to Keplera" : "Welcome to BORG"
const productLogo = isKepleraVersion ? kepleraLogo : borgLogo;

type Props = {};

type LocationState = {
  fromResetPassword: boolean,
  success: boolean
};

const LoginPage = (props: Props) => {

  const [error, setError] = React.useState("");
  const navigate = useNavigate();
  const locationState = useLocation().state as LocationState;

  // Functions to switch between views
  const showResetPassword = () => navigate("/password-reset");


  useEffect(() => {

    const errorMsg = localStorage.getItem("loginError");
    const loggedIn = localStorage.getItem("refresh-token");
    if (errorMsg) {
      setError(errorMsg);
      localStorage.removeItem("loginError");
    }
    else if (loggedIn) {
      // log user out if they go to the login page while already logged in
      handleLogout(navigate);
      setError("You've been logged out");
    }

  }, [error, navigate]);

  let successMessage;
  if (locationState && locationState.fromResetPassword && locationState.success) {
    successMessage = "Password reset successfully";
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Logo */}
      <div className="borg-logo" style={{ marginTop: '6vh' }}>
        <img alt="logo" src={productLogo} />
      </div>

      <MainPageTemplateColumnLayout title={productWelcome} error={error} message={successMessage}>
        <Login onNeedResetPassword={showResetPassword} />
      </MainPageTemplateColumnLayout>
    </div>
  );
};

export default LoginPage;
