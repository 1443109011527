import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Box, styled,
  DialogContent, Dialog, DialogActions, DialogTitle, Switch, Typography, DialogContentText, MenuItem,
  Select, FormControl, SelectChangeEvent
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Roles } from '../../BorgEnums';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
  getAllUsers, deleteUser, updateUserRole, toggleUserEnabled, forceUserPasswordReset,
  getAllOrganizations, updateUser,
} from '../../Services/BorgAPIClient';
import ChangeConfirmationDialog from '../../Components/ChangeConfirmationDialog';
import moment from 'moment';
import UserStatsHeader from '../../Components/UserStatsHeader';
import React from 'react';
import { LoggedInUserContext } from '../../Components/Admin/AdminPageContainer'; // Import context

type User = {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  rankTitle: string;
  roles: number[];
  enabled: boolean;
  resetPasswordPending: boolean;
  lastActive: Date;
  organization: string;
};

type Organization = {
  id: string;
  name: string;
  createdDate: Date;
  isPaid: boolean;
};

const CustomTableHead = styled(TableHead)({
  backgroundColor: 'black',
  '& th': {
    color: 'white',
    cursor: 'pointer',
  },
});

const UsersAdminPage: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [openConfirmResetPasswordDialog, setOpenConfirmResetPasswordDialog] = useState(false);
  const [userToChange, setUserToChange] = useState<User | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newRole, setNewRole] = useState<number>(Roles.User);
  const [sortBy, setSortBy] = useState<'username' | 'organization' | 'role' | 'lastActive' | 'status'>('username');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [openErrorNotification, setOpenErrorNotification] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorTitle, setErrorTitle] = React.useState("");
  const [errorAdditionalMessage, setErrorAdditionalMessage] = React.useState("");
  
  const loggedInUser = useContext(LoggedInUserContext);


  const fetchUsers = useCallback(async () => {
    if (loggedInUser)  //if logged in user has been obtained
    {
      try {
        let result: { data: User[]; }; //initialize with blank list of users

        if (loggedInUser?.roles[0] === Roles.OrganizationAdmin)
          result = await getAllUsers(loggedInUser.organization);
        else
          result = await getAllUsers();

        setUsers(result.data as User[]);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    }
  }, [loggedInUser]);

  const fetchOrganizations = useCallback(async () => {
    try {
      const result = await getAllOrganizations();
      setOrganizations(result.data as Organization[]);
    } catch (error) {
      console.error('Failed to fetch organizations:', error);
    }
  }, []);

  //Filtering roles in edit roles list per user Role
  const filteredRoles = Object.values(Roles).filter(value =>
    typeof value === 'number' &&
    !(loggedInUser?.roles[0] === Roles.OrganizationAdmin && value === Roles.SystemAdmin)
  );

  // see https://dev.to/jasmin/how-to-use-async-function-in-useeffect-5efc
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.roles[0] !== Roles.OrganizationAdmin)    //Don't fetch Organizations if the user is of the role Organization Admin
      fetchOrganizations();
  }, [loggedInUser]); //loggedInUser needs to be set before organizations can be fetched

  const closeConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const closeConfirmResetPasswordDialog = () => {
    setOpenConfirmResetPasswordDialog(false);
  };

  const handleDeleteClick = (user: User) => {
    const currentUserIdString = localStorage.getItem('userId');
    if (!currentUserIdString) {
      console.error("User ID not found in localStorage");
      return;
    }
    const currentUserId = JSON.parse(currentUserIdString);
    if (user.id === currentUserId) {
      alert("You cannot delete the logged-in account.");
      return;
    }
    setUserToChange(user);
    setOpenConfirmDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (userToChange) {
      await deleteUser(userToChange.id);
      fetchUsers();
    }
    setOpenConfirmDeleteDialog(false);
    setUserToChange(null);
  };

  const handleEditClick = (user: User) => {
    const currentUserIdString = localStorage.getItem('userId');
    if (!currentUserIdString) {
      console.error("User ID not found in localStorage");
      return;
    }
    const currentUserId = JSON.parse(currentUserIdString);
    if (user.id === currentUserId) {
      alert("You cannot change the role for the logged-in account.");
      return;
    }
    setUserToChange(user);
    setNewRole(user.roles[0]);
    setOpenEditDialog(true);
  };

  const handleToggleEnable = async (user: User) => {
    setUserToChange(user);  //(used to update stats panel) set user being updated 

    try {
      await toggleUserEnabled(user.id);
      fetchUsers(); // Update user list after successful API call
    } catch (error) {
      const errorMessage = (error as { title?: string }).title || 'An unknown error occurred';
      setErrorTitle("Error Toggling User Enable/Disable")
      setErrorMessage(errorMessage);
      setOpenErrorNotification(true);
    }
    setUserToChange(null); //(used to update stats panel) Clear out user set 
  };

  const handleResetPasswordClick = async (user: User) => {
    const currentUserIdString = localStorage.getItem('userId');
    if (!currentUserIdString) {
      console.error("User ID not found in localStorage");
      return;
    }
    setUserToChange(user);
    setOpenConfirmResetPasswordDialog(true);
  }

  const handleConfirmResetPassword = async () => {
    if (userToChange) {
      await forceUserPasswordReset(userToChange.id);
      userToChange.resetPasswordPending = true;
    }
    setOpenConfirmResetPasswordDialog(false);
    setUserToChange(null);
  };

  const handleConfirmRoleChange = async () => {
    if (userToChange) {
      if (userToChange.roles.includes(newRole)) {
        alert("User already has this role.");
        setOpenEditDialog(false);
        return;
      }

      await updateUserRole(userToChange.id, newRole);
      fetchUsers();
    }

    setOpenEditDialog(false);
    setUserToChange(null);
  };

  const handleSort = (column: 'username' | 'organization' | 'role' | 'lastActive' | 'status') => {
    const isAsc = sortBy === column && sortDirection === 'asc';
    setSortBy(column);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const sortedUsers = [...users].sort((a, b) => {
    const stringCompare = (aValue: string, bValue: string) => {
      if (!aValue) return 1;
      if (!bValue) return -1;
      if (aValue.toLowerCase() < bValue.toLowerCase()) return -1;
      if (aValue.toLowerCase() > bValue.toLowerCase()) return 1;
      return 0;
    };
    const numericCompare = (aValue: number, bValue: number) => {
      if (!aValue) return 1;
      if (!bValue) return -1;
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    };
    const dateCompare = (aValue: Date, bValue: Date) => {
      if (!aValue) return 1;
      if (!bValue) return -1;
      if (aValue > bValue) return -1;
      if (aValue < bValue) return 1;
      return 0;
    };
    const boolCompare = (aValue: boolean, bValue: boolean) => {
      if (!aValue) return 1;
      if (!bValue) return -1;
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    };
    const sortOrder = sortDirection === 'asc' ? 1 : -1;
    switch (sortBy) {
      case 'username': return stringCompare(a.username, b.username) * sortOrder;
      case 'organization': return stringCompare(a.organization, b.organization) * sortOrder;
      case 'role': return numericCompare(a.roles[0], b.roles[0]) * sortOrder;
      case 'lastActive': return dateCompare(a.lastActive, b.lastActive) * sortOrder;
      case 'status': return boolCompare(a.enabled, b.enabled) * sortOrder;
    }
  });

  const calculateTimeAgo = (time: Date) => {
    return moment(time).fromNow();
  };

  //Components for Organization Drop down to function.
  interface OrganizationDropdownProps {
    user: User;
    onChange: (event: SelectChangeEvent<string>) => void;
  }
  //Handle function
  const handleOrganizationChange = (event: SelectChangeEvent<string>, user: User) => {
    // Set selectedOrganization to drop down menu item pressed
    const selectedOrganization = event.target.value;
    //Replace Organization with selected organization
    user.organization = selectedOrganization;
    //Pass new user object to be updated in backend
    updateUser({
      id: user.id,
      email: user.email,
      organization: user.organization,
      firstName: user.firstName,
      lastName: user.lastName,
      rankTitle: user.rankTitle
    })
    fetchOrganizations(); //Update drop down to show new selection
  };

  //Components for JSX
  const OrganizationDropdown: React.FC<OrganizationDropdownProps> = ({ user, onChange }) => {
    return (
      <FormControl sx={{ minWidth: 120 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={user.organization || " "}  //Handle legacy users with out organizations assigned
          onChange={(event) => handleOrganizationChange(event, user)}
          variant='standard'
        >
          {organizations.map(organization => (
            <MenuItem key={organization.id} value={organization.name}>
              {organization.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  return (
    <div>
      <Box sx={{
        marginY: 2,
        borderRadius: 1,
        width: '90%',
        marginX: 'auto',
      }}>
        <UserStatsHeader statsChanged={userToChange !== null} />
      </Box>
      <Box sx={{
        overflow: 'auto',
        borderRadius: 1,
        width: '90%',
        marginX: 'auto',
        marginY: 2,
        bgcolor: 'primary.dark'
      }}>
        <TableContainer component={Paper}>
          <Table>
            <CustomTableHead>
              <TableRow>
                <TableCell onClick={() => handleSort('username')} sx={{ width: '10%' }}>
                  Username {sortBy === 'username' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                {loggedInUser?.roles[0] !== Roles.OrganizationAdmin && (    //Don't render Organization column if user role is Organization Admin 
                  <TableCell onClick={() => handleSort('organization')} sx={{ width: '10%' }}>
                    Organization {sortBy === 'organization' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                  </TableCell>
                )}
                <TableCell onClick={() => handleSort('role')} sx={{ width: '10%' }}>
                  Role {sortBy === 'role' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('lastActive')} sx={{ width: '10%' }}>
                  Last Active {sortBy === 'lastActive' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell onClick={() => handleSort('status')} sx={{ width: '10%' }}>
                  Status {sortBy === 'status' ? (sortDirection === 'asc' ? '▲' : '▼') : ''}
                </TableCell>
                <TableCell sx={{ width: '25%' }} style={{ textAlign: 'center' }}>Actions</TableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody id="list-users">
              {sortedUsers.map((user, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: user.enabled ? '' : '#8e5d64',
                  }}
                >
                  <TableCell>{user.username}</TableCell>
                  {loggedInUser?.roles[0] !== Roles.OrganizationAdmin && (  //Don't render Organization column if user role is Organization Admin
                    <TableCell>
                      <OrganizationDropdown user={user} onChange={(event) => handleOrganizationChange(event, user)} />
                    </TableCell>
                  )}
                  <TableCell>
                    {user.roles.map((role, index) => (
                      <Typography key={index}>{Roles[role]}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {user.lastActive ? (
                      <Typography>
                        {calculateTimeAgo(user.lastActive)}
                      </Typography>
                    ) : ''}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Typography>{user.enabled ? 'Enabled' : 'Disabled'}</Typography>
                      <Switch
                        checked={user.enabled}
                        onChange={() => handleToggleEnable(user)}
                        color="primary"
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                      <Button onClick={() => handleResetPasswordClick(user)}>
                        {user.resetPasswordPending ? 'PASSWORD PENDING' : 'Reset Password'}
                      </Button>
                      <Button startIcon={<EditIcon />} onClick={() => handleEditClick(user)}
                        disabled={loggedInUser?.roles[0] !== Roles.SystemAdmin && user.roles[0] === Roles.SystemAdmin}> {/* Disable edit role if the user in the row is System Admin and the current user is not System Admin */}
                        Edit Role
                      </Button>
                      <Button startIcon={<DeleteIcon />} onClick={() => handleDeleteClick(user)}>
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Deletion Confirmation Dialog */}
      <ChangeConfirmationDialog
        title="Confirm Delete"
        message={`This action cannot be undone.  Are you sure you want to delete "${userToChange?.username}"?`}
        open={openConfirmDeleteDialog}
        onClose={closeConfirmDeleteDialog}
        onCancel={closeConfirmDeleteDialog}
        onConfirm={handleConfirmDelete}
      />
      {/* Password Reset Confirmation Dialog */}
      <ChangeConfirmationDialog
        title="Confirm Password Reset"
        message={`This action cannot be undone.  Are you sure you want to reset password for "${userToChange?.username}"?`}
        open={openConfirmResetPasswordDialog}
        onClose={closeConfirmResetPasswordDialog}
        onCancel={closeConfirmResetPasswordDialog}
        onConfirm={handleConfirmResetPassword}
      />
      {/* Role Change Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>{"Change User Role"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select a new role for the user.
          </DialogContentText>
          <Select
            id="select-userrole"
            value={newRole}
            onChange={(event) => setNewRole(event.target.value as number)}
            variant='standard'
            fullWidth
          >
            {filteredRoles.map((roleValue, index) => (
              <MenuItem key={index} value={roleValue}>
                {Roles[roleValue as keyof typeof Roles]}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmRoleChange} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {/* Error Notification Dialog */}
      <Dialog open={openErrorNotification} onClose={() => setOpenErrorNotification(false)}>
        <DialogTitle>{errorTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {errorMessage}
            <p/>
            {errorAdditionalMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorNotification(false)}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UsersAdminPage;