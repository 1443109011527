import { useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import DashboardPage from "./Pages/DashboardPage";
import LoginPage from "./Pages/LoginPage";
import UnityViewer from "./Pages/UnityViewer";
import SandboxesAdminPage from "./Pages/Admin/SandboxesAdminPage";
import RBAC from "./Components/RBAC";
import { Roles } from "./BorgEnums";
import Error from "./Pages/ErrorPages/Error";
import AdminPageContainer from "./Components/Admin/AdminPageContainer";
import AdminDashboardPage from "./Pages/Admin/AdminDashboardPage";
import UsersAdminPage from "./Pages/Admin/UsersAdminPage";
import OrganizationsAdminPage from "./Pages/Admin/OrganizationsAdminPage";
import LeaderboardsAdminPage from "./Pages/Admin/LeaderboardsAdminPage";
import PermissionsAdminPage from "./Pages/Admin/PermissionsAdminPage";
import { AxiosInterceptorSetup } from "./Services/AxiosService";
import { ThemeProvider, CssBaseline } from "@mui/material";
import BorgTheme from "./GlobalStyles/MuiTheme";
import SuccessfulPayment from "./Pages/SuccessfulPayment";
import FailedPayment from "./Pages/FailedPayment";
import RegisterPage from "./Pages/RegisterPage";
import PasswordResetPage from "./Pages/PasswordResetPage";
import SystemAdminPage from "./Pages/Admin/SystemAdminPage";
import BannerMessage from "./Components/BannerMessage";
import { getBannerMessage } from "./Services/BorgAPIClient";

type BannerMessageType = {
  startDate: Date;
  endDate: Date;
  message: string;
};

interface Props { }

function RouterComp(props: Props) {

  const navigate = useNavigate();
  const location = useLocation();

  const [isLoaded, setIsLoaded] = useState(false)
  const [message, setMessage] = useState<string>();
  const [userDismissedBanner, setUserDismissedBanner] = useState<boolean>(true); // by default, user has not dismissed banner
  const [serverBannerMessageIsValid, setServerBannerMessageIsValid] = useState<boolean>(false); // by default, server message is not valid

  const unityViewerRef = useRef<any>(null);

  const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";
  const productName = isKepleraVersion ? "Keplera" : "BORG Space"
  
  useEffect(() => {
    fetchBannerMessage();
    document.title = location.pathname.includes("/admin") ? productName + " - Admin Panel" : productName;
  }, []);

  const isWithinBannerDateRange = (bannerStartDate: Date, bannerEndDate: Date) => {
    // Check if either start or end date is null
    if (!bannerStartDate || !bannerEndDate) {
      return false; // Return false if either date is null
    }

    const currentDate = new Date();
    const showBanner = currentDate >= bannerStartDate && currentDate <= bannerEndDate;

    return showBanner;
  };

  const onCloseBanner = () => {
    setUserDismissedBanner(false);
    sessionStorage.setItem("showBanner", "false");
    unityViewerRef.current?.handleResize();
    console.log("Show Banner: false");
  }

  const fetchBannerMessage = useCallback(async () => {

    let isWithinDateRange = false;
    if(userDismissedBanner) {
      // if the user hasn't hidden the banner, then get the latest banner message
      try {
        const result = await getBannerMessage();
        const bannerMessageData = result.data as BannerMessageType;
        // show it if its within the banner's effective date range
        isWithinDateRange = isWithinBannerDateRange(new Date(bannerMessageData.startDate), new Date(bannerMessageData.endDate));
        setServerBannerMessageIsValid(isWithinDateRange);
        setMessage(bannerMessageData.message);
        sessionStorage.setItem("showBanner", isWithinDateRange ? "true" : "false");
      } catch (error) {
        console.error('Failed to fetch Banner Message:', error);
        sessionStorage.setItem("showBanner", "false");
      }
    }
    console.log(`Show Banner: ${isWithinDateRange}`);

  }, []);

  const getRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/password-reset" element={<PasswordResetPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/play" element={<UnityViewer ref={unityViewerRef}/>} />
        <Route element={<RBAC allowedRole={[Roles.SystemAdmin, Roles.OrganizationAdmin]} />}>
          <Route path="/admin" element={<AdminDashboardPage />} />
          <Route path="/admin/users" element={<UsersAdminPage />} />
          <Route path="/admin/organizations" element={<OrganizationsAdminPage />} />
          <Route path="/admin/sandboxes" element={<SandboxesAdminPage />} />
          <Route path="/admin/leaderboards" element={<LeaderboardsAdminPage />} />
          <Route path="/admin/permissions" element={<PermissionsAdminPage />} />
          <Route path="/admin/system" element={<SystemAdminPage />} />
        </Route>
        <Route path="/success" element={<SuccessfulPayment />} />
        <Route path="/failed" element={<FailedPayment />} />
        <Route path="/forbidden" element={
          <Error
            errorCode={403}
            errorText={"Access Denied (Forbidden)"}
            errorHelp={"You do not have permission to access this resource"}
          />}
        />
        <Route path="/server-error" element={
          <Error
            errorCode={500}
            errorText={"Unexpected server error!"}
            errorHelp={"If problem persists contact support"}
          />}
        />
        <Route path="*" element={
          <Error
            errorCode={404}
            errorText={"Page not found!"}
            errorHelp={"The resource you are looking for does not exist."}
          />}
        />
      </Routes>
    );
  }

  if (!isLoaded) {
    setIsLoaded(true)
    AxiosInterceptorSetup(navigate);
  }

  let content;
  if (location.pathname.includes("/admin")) {
    content = (
      <AdminPageContainer>
        {getRoutes()}
      </AdminPageContainer>
    );
  } else {
    // Only show the BannerMessage if the current date and time is within the banner date range and the banner has not been closed by the user
    content = (
      <div>
        <BannerMessage visible={userDismissedBanner && serverBannerMessageIsValid} message={message} onCloseBanner={onCloseBanner} />
        <div>
          {getRoutes()}
        </div>
      </div>
    );
  }
  return (
    <ThemeProvider theme={BorgTheme}>
      <CssBaseline />
      {content}
    </ThemeProvider>
  );
}

export default RouterComp;
