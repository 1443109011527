import React from 'react';
import UserLimitPanel from './StatsHeaderPanels/UserLimitPanel';
import AdminCountPanel from './StatsHeaderPanels/AdminCountPanel';

type statChangeType = {
  statsChanged: boolean;
};

const UserStatsHeader = ({ statsChanged }: statChangeType) => {
  return (
    <div className="user-stats-header" style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
      <UserLimitPanel statsChanged={statsChanged} />
      <AdminCountPanel statsChanged={statsChanged} />
    </div>
  );
};

export default UserStatsHeader;