import React from "react";
import { Box, Typography } from '@mui/material';

type Props = {};

const AdminDashboardPage = (props: Props) => { 

  return (
    <Box textAlign='center' sx={{ margin: 10 }}>
      <Typography align='center' variant='h2' gutterBottom>Welcome to the Admin Panel</Typography>
      <Typography align='center'>
        Click the nav buttons on the app bar above to administer different BORG resources.
      </Typography>
    </Box>
  );
}

export {};
export default AdminDashboardPage;