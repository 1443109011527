import React, { useState, useEffect } from 'react';
import { addGroup, getGroups, getSession, getUser, removeGroup, startSession, stopSession } from '../Services/BorgAPIClient';
import { User } from '../BorgTypes';
import { Roles } from '../BorgEnums';
import  LogoutButton  from "../Components/LogoutButton"
import { Link, useNavigate } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MainPageTemplateColumnLayout from './Templates/MainPageTemplateColumnLayout';
import CreateGroupModal from 'src/Components/CreateGroupModal';
import GroupManagement from 'src/Components/GroupManagment';

import borgLogo from "../assets/img/Borg-logo.png";
import kepleraLogo from "../assets/img/Keplera-logo.png";

const isKepleraVersion = process.env.REACT_APP_PRODUCT_NAME === "keplera";
const productLogo = isKepleraVersion ? kepleraLogo : borgLogo;

const MAX_GROUPS_PER_USER = 3

interface groupDataType {
  id: string,
  name: string,
  password: string,
  ownerId: string,
  caption: "",
  memberIds: [],
  activeSessionId: string,
  Competitions: {
    id: string,
    name: string,
    startDate: Date;
    endDate: Date | null;
    type: number | null,
  }
}

function DashboardPage() {

  const navigate = useNavigate();

  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);
  const [canPlay, setCanPlay] = useState<boolean | null>(null);
  const [error, setError] = React.useState("");
  const [isCreateGroupOpen, setIsCreateGroupOpen] = useState(false);
  const [currentGroupList, setCurrentGroupList] = useState<groupDataType[]>([]);


  const toggleCreateGroupModal = () => {
    setIsCreateGroupOpen(!isCreateGroupOpen);
  }

  useEffect(() => {
    const getLoggedInUser = async () => {
      const userId = JSON.parse(localStorage.getItem('userId') as string);
      const canPlayStatus = JSON.parse(localStorage.getItem('canPlay') as string);
      let user;
      if (userId) {
        try {
          user = await getUser(userId.replace(/"|'/g, ''));
          if (user) {
            setLoggedInUser(user.data);
            setCanPlay(canPlayStatus);
          }
        } catch (err: any) {
          if (err && err.errors) {
            setError(`Error: No logged in user: ${err.title}`);
          } else if (err) {
            setError(err.title);
          } else {
            setError("Error: No logged in user");
          }
        }
      } else {
        setError("Error: No logged in user");
      }
    };

    getLoggedInUser();
  }, []);

  useEffect(() => {
    fetchGroup();
  }, [loggedInUser]); //After user information is obtained execute fetchGroup

  useEffect(() => {
    fetchSession();
  }, [currentGroupList]); //After group list is obtained execute fetchSession

  const fetchGroup = async () => {
    if (loggedInUser) {
      const response = await getGroups(loggedInUser.id);
      await setCurrentGroupList(response.data);
    }
  };

  const fetchSession = async () => {
    if (currentGroupList.length > 0) {
      const updatedGroups = currentGroupList.map(async (group) => {
        const response = await getSession(group.id);
        return { ...group, Competitions: response.data }; // Create a new object
      });

      // Wait for all promises to resolve before updating state
      const newGroupList = await Promise.all(updatedGroups);

    // Check if newGroupList is different from currentGroupList
    const currentGroupListJson = JSON.stringify(currentGroupList);
    const newGroupListJson = JSON.stringify(newGroupList);

    if (currentGroupListJson !== newGroupListJson) {//Only execute setCurrentGroupList if newGroup has changed
        setCurrentGroupList(newGroupList);
      }
    }
  };

  const handlePlayClick = () => {
    navigate("/play");
  }

  const handleCreateGroupConfirm = async (groupName: string) => {

    const fullGroupData = {
      name: groupName,
      ownerId: loggedInUser?.id,
      caption: "",
      memberIds: [],
    };

    //API Call to POST new Group
    try {
      const response = await addGroup(fullGroupData); // Call to add group
    } catch (err: any) {
      console.error("An error occurred during registration processing.", err);
    }
    toggleCreateGroupModal(); //Close modal
    fetchGroup();
  }

  const handleDeleteGroup = async (groupId: string) => {
    await removeGroup(groupId);
    fetchGroup();
  }
  const handleStartSession = async (groupData: groupDataType) => {
    await startSession(groupData);
    fetchGroup();
  }
  
  const handleStopSession = async (groupData: groupDataType) => {
    await stopSession(groupData.id);
    fetchGroup();
  }

  const handleAdminClick = () => {
    window.open("/admin/users", "_blank", "noreferrer");
  }

  let playButtonJsx = null;
  let createGroupButtonJsx = null;
  let accountDisabledJsx = null;
  let adminButtonJsx = null;

  if (loggedInUser) {
    if (loggedInUser.enabled) {
      if (canPlay) {        
        playButtonJsx = (<button className="secondary-btn" onClick={handlePlayClick}>Play</button>);
        if (loggedInUser.roles?.includes(Roles.SystemAdmin) || loggedInUser.roles?.includes(Roles.OrganizationAdmin)) {
            adminButtonJsx = (
              <button className="secondary-btn" onClick={handleAdminClick}>Admin <OpenInNewIcon sx={{ fontSize: 14 }} /></button>
            )
          if (currentGroupList.length < MAX_GROUPS_PER_USER) //If the user owns 3 or more groups then they will not be able to create more - This will be modified eventually
            createGroupButtonJsx = (<button className="secondary-btn" onClick={toggleCreateGroupModal}>Create Group</button>);
        }

      } else {
        accountDisabledJsx = (
          <div className="error">
            Your organization or subscription is inactive. Contact your administrator.
          </div>
        );
      }
    } else {
      accountDisabledJsx = (
        <div className="error">
          Account Disabled. Contact your administrator.
        </div>
      );
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {/* Logo */}
      <div className="borg-logo" style={{ marginTop: '6vh' }}>
        <img alt="logo" src={productLogo} />
      </div>
      {/* Dashboard Windows */}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {/* Options Window */}
        <MainPageTemplateColumnLayout title="Options">
          {accountDisabledJsx}
          {playButtonJsx}
          {createGroupButtonJsx}
          {adminButtonJsx}
          { !error && loggedInUser && <LogoutButton /> }
          {!error && !loggedInUser && <div>Getting user information</div>}
          {error && (
            <div>
              <div className="error">{error}</div>
              <p />
              <div className="footer">
                <Link className="link-button" to="/">
                  Back to Login
                </Link>
              </div>
            </div>
          )}
          <CreateGroupModal
            isOpen={isCreateGroupOpen}
            onClose={toggleCreateGroupModal}
            onConfirm={handleCreateGroupConfirm}
          />
        </MainPageTemplateColumnLayout>
        {/* Group Management Window*/}
        {canPlay && currentGroupList.length !== 0 && (<MainPageTemplateColumnLayout title="Group Management">
          <GroupManagement
            groups={currentGroupList}
            onDeleteGroup={handleDeleteGroup}
            onStartSession={handleStartSession}
            onStopSession={handleStopSession}
          />
        </MainPageTemplateColumnLayout>)}
      </div>
    </div>
  );
}

export default DashboardPage;