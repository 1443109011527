import React, { forwardRef } from "react";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import Alert, { AlertProps } from "@mui/material/Alert";
import Grow from "@mui/material/Grow";

type CustomSnackbarProps = SnackbarProps & {
  children: React.ReactNode;
};

const CustomSnackbar = forwardRef(function CustomSnackbar(
  props: CustomSnackbarProps,
  ref: React.Ref<any>
) {
  return (
    <Snackbar {...props} TransitionComponent={Grow} ref={ref}>
      {props.children}
    </Snackbar>
  );
});

type CustomAlertProps = AlertProps & {
  children: React.ReactNode;
};

const CustomAlert = forwardRef(function CustomAlert(
  props: CustomAlertProps,
  ref: React.Ref<any>
) {
  const { onClose, severity } = props;
  return (
    <Alert onClose={onClose} severity={severity} ref={ref}>
      {props.children}
    </Alert>
  );
});

export { CustomSnackbar, CustomAlert };