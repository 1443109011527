import axios from "axios";

const getApiUrl = () => {
  if (typeof window !== 'undefined') {  //condition necessary to make Cypress initialize. Checks for browser environment execution
    // window is run time
    return window._env_.REACT_APP_API_URL;
  } else { //if system is Node.js/Cypress environemnt (browserless) through entire execution this will set the local dev url
    // process is build time
    return process.env.REACT_APP_API_URL; // fallback URL
  }
};

let hostName = getApiUrl() + '/api';
// Dev
if (process.env.NODE_ENV === 'development') {
  hostName = 'https://localhost:8082/api';
}

const parseResponseFetch = async (response) => {
  let data = await response.data;
  return data;
};

const getStandardError = (error) => {
  if (error.code === 'ERR_NETWORK' || error.code === 'ERR_CANCELED' || error.code === 'ECONNABORTED') {
    return { "title": "Error completing request. Is the server down?" };
  } else if (error.response) {
    // Request made and server responded
    //console.error(error.response);
    if (error.response.headers && error.response.data) {
      // handle error based on headers
      if (error.response.headers["content-type"]?.includes("application/problem+json")) {
        return { "title": error.response.data.title, "errors": error.response.data.errors };
      } else if (error.response.headers["content-type"]?.includes("application/json")) {
        return { "title": error.response.data.message };
      } else if (error.response.headers["content-type"]?.includes("text/plain")) {
        return { "title": error.response.data };
      }
    } else {
      return { "title": error.response.data };
    }
  } else if (error.request) {
    // The request was made but no response was received
    //console.error(error.request);
    return { "title": "Error completing request. Is the server down?" };
  } else if (error) {
    // Something happened in setting up the request that triggered an Error
    //console.error(error);
    return { "title": error.message };
  } else {
    //console.error("Unknown Error")
    return { "title": "Unknown Error" };
  }
}

// add a timeout
axios.interceptors.request.use(config => {
  config.timeout = 5000; // Wait for 5 seconds before timing out
  return config;
});

//Check if response is 401 for a logged-in user and automatically retry the request
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {

    console.log("Axios Error Code: " + error.code);
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_CANCELED' || error.code === 'ECONNABORTED') {
      return Promise.reject(error);
    }

    let authToken = localStorage.getItem("x-auth-token");
    if (authToken == null) {
      // do nothing if not logged in
      return Promise.reject(error);
    }
    if (authToken != null && error.response && error.response.status === 401 && !error.config._retry) {
      let token = localStorage.getItem("refresh-token");
      // Try to get a new auth token
      await axios
        .post(hostName + `/auth/session/token`, token, { withCredentials: true })
        .then((res) => {
          localStorage.setItem("refresh-token", res.data.refreshToken);
        })
        .catch((error) => {
          //send to login page when logged out
          return Promise.reject(error);
        });
      // Retry the request
      error.config._retry = true;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export const getWebApiUrl = () => {
  return hostName;
};

export const fetchFromHttp = (request, payload) => {
  return new Promise(async (resolve, reject) => {
    axios({
      // Endpoint to send files
      url: hostName + request,
      method: "GET",
      withCredentials: true,
      data: payload,
    })
      // Handle the response from backend here
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        let standardError = getStandardError(err);
        reject(standardError);
      }, reject);
  });
};

export const postToHttp = (request, payload) => {
  return new Promise(async (resolve, reject) => {

    axios({
      // Endpoint to send files
      url: hostName + request,
      method: "POST",
      data: payload,
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', },
    })
      // Handle the response from backend here
      .then((res) => {
        resolve(parseResponseFetch(res));
      })
      .catch((err) => {
        let standardError = getStandardError(err);
        reject(standardError);
      });
  });
};

export const patchToHttp = (request, payload) => {
  return new Promise(async (resolve, reject) => {
    axios({
      // Endpoint to send files
      url: hostName + request,
      method: "PATCH",
      data: payload,
      withCredentials: true,
    })
      // Handle the response from backend here
      .then((res) => {
        resolve(parseResponseFetch(res));
      })
      .catch((err) => {
        let standardError = getStandardError(err);
        reject(standardError);
      });
  });
};

export const putToHttp = (request, payload) => {
  return new Promise(async (resolve, reject) => {
    axios({
      // Endpoint to send files
      url: hostName + request,
      method: "PUT",
      data: payload,
      withCredentials: true,
    })
      // Handle the response from backend here
      .then((res) => {
        resolve(parseResponseFetch(res));
      })
      .catch((err) => {
        let standardError = getStandardError(err);
        reject(standardError);
      });
  });
};

export const deleteFromHttp = (request, payload) => {
  return new Promise(async (resolve, reject) => {

    axios({
      // Endpoint to send files
      url: hostName + request,
      method: "DELETE",
      data: payload,
      withCredentials: true,
      headers: { 'Content-Type': 'application/json', },
    })
      // Handle the response from backend here
      .then((res) => {
        resolve(parseResponseFetch(res));
      })
      .catch((err) => {
        let standardError = getStandardError(err);
        reject(standardError);
      });
  });
};

export const AxiosInterceptorSetup = navigate => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      let authToken = localStorage.getItem("x-auth-token");
      if (authToken != null && error.response && error.response.status === 401 && !error.config._retry) {
        let token = localStorage.getItem("refresh-token");
        // Try to get a new auth token
        await axios
          .post(hostName + `/auth/session/token`, token, { withCredentials: true })
          .then((res) => {
            localStorage.setItem("refresh-token", res.data.refreshToken);
          })
          .catch((error) => {
            //send to login page when logged out
            return Promise.reject(error);
          });
        // Retry the request
        error.config._retry = true;
        return axios(error.config);
      }
      //If the response is 401, redirect to login page
      else if (error.response.status === 401) {
        navigate("/");
      }
      //If the response is 403, redirect to forbidden page
      else if (error.response.status === 403) {
        navigate("/forbidden");
      }
      //If the response is 404, redirect to not found page
      else if (error.response.status === 404) {
        navigate("/not-found");
      }
      else if (error.response.status >= 500) {
        navigate("/server-error");
      }

      return Promise.reject(error);
    }
  );
}