import React from 'react';
import { handleLogout } from './LogoutUtils';
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
    const navigate = useNavigate();

    return (
        <button
            type="button"
            className="secondary-btn"
            onClick={() => handleLogout(navigate)}
        >
            Logout
        </button>
    );
}

export default LogoutButton;
