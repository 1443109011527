import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.scss";
import RouterComp from "./App";

export default function App() {

  return (
    <BrowserRouter>
      <RouterComp />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
